<template>
  <nav class="navbar text-white bg-primary navbar-dark bg-gradient navbar-expand-lg shadow-sm">
    <div class="container">
      <a href="#" class="navbar-brand fw-bold">PORTAL KARIR</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
              aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" v-for="(item,i) in menu" :key="i">
            <router-link :to="item.link" class="nav-link">{{item.text}}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view/>
  <Footer/>
</template>
<script>
import * as bootstrap from '@/assets/js/bootstrap.bundle.min'
import Footer from '@/components/Footer'
export default {
  name: "App",
  components:{
    Footer
  },
  data() {
    return {
      menu: [
        {link: '/', text: 'Home'},
        {link: '/alumni', text: 'Alumni'},
        {link: '/register', text: 'Register'},
      ]
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import "./assets/css/bootstrap.min.css";
#app {
  font-family: 'Poppins', sans-serif;
}
.router-link-active{
  color: white;
}
</style>
